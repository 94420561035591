import React from "react";
import "../styles/ch-basic.scss";
import "../styles/forms.scss";

import {Header} from '../App';
import {ChurchHomeFooter} from './footer';

import {ChurchSubmissions} from './form/ChurchSubmissions';

// Form ideas from https://github.com/blizzerand/react-forms
export class ChurchSubmissionsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formLocation: [
        {churchBasicsComplete: false},
      ],

      churchName: '',
      phoneNumber: '',
      emailAddress: '',

      websiteURL: '',
      facebookURL: '',
      instagramURL: '',

      churchAddressStreet: '',
      churchAddressCity: '',
      churchAddressState: '',
      churchAddressZIP: '',

      cbsAreaLevel: [],
      employeeCount: [],
      leadPastorName: '',

      primaryLanguage: [],
      primaryEthnicity: [],
      churchSize: [],
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  createChurchId = () => {
    var ALPHABET = '23456789abdegjkmnpqrvwxyz';

    var ID_LENGTH = 12;

    ID_LENGTH -= 4;
    var rtn = '';
    console.log('rtn at line 55 = ' + rtn);
    for (var i = 0; i < ID_LENGTH; i++) {
      rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }

    console.log('rtn at line 60 = ' + rtn);

    rtn += '-';
    for (i = 0; i < 4; i++) {
      rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }

    console.log('rtn at line 65 = ' + rtn);

    return rtn;
    //
    // var UNIQUE_RETRIES = 9999;
    //
    // var generateUnique = function(previous) {
    //   previous = previous || [];
    //   var retries = 0;
    //   var id;
    //
    //   // Try to generate a unique ID,
    //   // i.e. one that isn't in the previous.
    //   while(!id && retries < UNIQUE_RETRIES) {
    //     id = generate();
    //     if(previous.indexOf(id) !== -1) {
    //       id = null;
    //       retries++;
    //     }
    //   }
    //
    //   return id;
    // };
  }

  writeToDatabase() {
    const userInput = this.state;
    const denominationInput = document.getElementById('selectedSearchOption').innerHTML;
    let dataToWrite = {
      "church_id": this.state.church_id,

      "churchName": userInput.churchName,
      "phoneNumber": userInput.phoneNumber,
      "emailAddress": userInput.emailAddress,

      "websiteURL": userInput.websiteURL,
      "facebookURL": userInput.facebookURL,
      "instagramURL": userInput.instagramURL,

      "churchAddressStreet": userInput.churchAddressStreet,
      "churchAddressCity": userInput.churchAddressCity,
      "churchAddressState": userInput.churchAddressState,
      "churchAddressZIP": userInput.churchAddressZIP,

      "churchDenomination": denominationInput,
      "cbsAreaLevel": userInput.cbsAreaLevel,
      "employeeCount": userInput.employeeCount,
      "leadPastorName": userInput.leadPastorName,

      "primaryLanguage": userInput.primaryLanguage,
      "primaryEthnicity": userInput.primaryEthnicity,
      "churchSize": userInput.churchSize,
    }

    fetch('https://api.churchhome.co/v1/congregations/submit', {
      method: "POST",
      headers: {
        'Origin': 'churchhome.co',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': 'XyAg3Kp1Fc7buMV8jRMkA8K7fqYdkXfI3fDY28IA',
      },
      body: JSON.stringify(dataToWrite),
    }).then(response => {
      if (response.ok) {
        return response;
      } else {
        throw new Error('Something went wrong. The server responded with something other than 200.');
      }
    }).then(response => {
      console.log("Successfully saved.");

      if (response.ok) {
        var formBody = document.getElementById("formBody");
        formBody.parentNode.removeChild(formBody);
        var formContainer = document.getElementById("formContainer");
        console.log(formContainer);
        var confirmationMessage = "<h1>Thanks!</h1><p>You're helping others find their flock, and maybe yours!</p><a href='/submit/pastors'><button class='returnButton'>Submit your own responses</button></a>"
        console.log(confirmationMessage);
        var confirmationElement = document.createElement('div');
        confirmationElement.classList.add('confirmationMessage');
        confirmationElement.innerHTML = confirmationMessage;
        formContainer.appendChild(confirmationElement);
      }

    }).catch(error => {
      console.log('error: ' + error);

      var formBody = document.getElementById("formBody");
      formBody.parentNode.removeChild(formBody);
      var formContainer = document.getElementById("formContainer");
      console.log(formContainer);

      var errorMessage = `<h1>Oops!</h1><p>Something didn't quite work.</p><a href='mailto:michael@churchhome.co?subject=Error%20Message&body=I%20received%20an%20error%20with%20the%20message:%20"${error}"".'><button class='returnButton'>Let us know</button></a>`
      console.log(errorMessage);
      var errorElement = document.createElement('div');
      errorElement.classList.add('confirmationMessage');
      errorElement.innerHTML = errorMessage;
      formContainer.appendChild(errorElement);
    });
  }

  handleFormSubmit(e) {
    this.writeToDatabase();
  }

  handleChange(event, checkboxId) {
    const target = event.target;
    var value = event.target.value;

    const inputName = target.name;
    this.setState({
      [inputName]: value
    });

    if (event.target.name === 'churchSize') {
      this.setState({ churchSize: [...this.state.churchSize, event.target.value] })
    }

    if (event.target.name === 'primaryEthnicity') {
      this.setState({ primaryEthnicity: [...this.state.primaryEthnicity, event.target.value] })
    }

    if (event.target.name === 'primaryLanguage') {
      this.setState({ primaryLanguage: [...this.state.primaryLanguage, event.target.value] })
    }

    if (event.target.name === 'cbsAreaLevel') {
      this.setState({ cbsAreaLevel: [...this.state.cbsAreaLevel, event.target.value] })
    }

    if (event.target.name === 'employeeCount') {
      this.setState({ employeeCount: [...this.state.employeeCount, event.target.value] })
    }
  }

  handleCheckbox(event) {
    const target = event.target;
    var value = event.target.value;
    const inputName = target.name;

    this.setState({
      [inputName]: value
    });

    // Set maximum check limit
    var limit = event.target.dataset.limit;
    limit = Number(limit);

    // Create state to count checked boxes
    var checkCountKey = (inputName + 'Check');
    if (this.state[checkCountKey] === undefined) {
      var checkCount = 0;
      this.setState({
        [checkCountKey]: checkCount
      })
    } else {
      checkCount = this.state[checkCountKey];
    }

    var checkedClass = 'checked-' + inputName;
    var disabledClass = 'disabled-' + inputName;
    var hiddenClass = 'hideInput-' + inputName;

    // If the event was triggered by already checked input, uncheck it
    var eventWasChecked = event.target.classList.contains(checkedClass);
    if (eventWasChecked === true) {
      event.target.classList.add(disabledClass);
      event.target.classList.remove(checkedClass);

      // Remove a corresponding check in the count
      checkCount = checkCount - 1;
      this.setState({
        [checkCountKey]: checkCount
      })
    } else {
      // Otherwise, increase checkcount on each event
      checkCount = checkCount + 1;
      this.setState({
        [checkCountKey]: checkCount
      })
    }

    // Get all checkboxes with the same input name
    var allRelatedBoxes = document.getElementsByName(inputName);

    // Loop to find checked boxes and update classes in DOM
    var i;
    for (i=0; i < allRelatedBoxes.length; i++) {
      if (allRelatedBoxes[i].checked === true) {
        allRelatedBoxes[i].classList.add(checkedClass);
        allRelatedBoxes[i].classList.remove(disabledClass);
      } else {
        allRelatedBoxes[i].classList.add(disabledClass);
        allRelatedBoxes[i].classList.remove(checkedClass);
      }
    }

    // If limit is reached, hide the unchecked boxes
    if (checkCount === limit) {
      var disabledBoxes = document.getElementsByClassName(disabledClass);
      for (i=0; i < disabledBoxes.length; i++) {
        disabledBoxes[i].parentNode.classList.add(hiddenClass);
      }
    } else {
      disabledBoxes = document.getElementsByClassName(disabledClass);
      for (i=0; i < disabledBoxes.length; i++) {
        disabledBoxes[i].parentNode.classList.remove(hiddenClass);
      }
    }
  }

  componentDidMount() {

    var newId = this.createChurchId();
    console.log(newId);

    this.setState({
      church_id: newId
    });
  }

  render() {

    return (
      <div className="formBody">
        <Header />
        <FormHeader selectedChurch="Submit a church" />
        <div id="formContainer">
          <form className="container-fluid" onSubmit={this.handleFormSubmit} id ="submissionForm">

            <ChurchSubmissions onChange={this.handleChange.bind(this)}
                            userEntry={this.state}
                            handleFormSubmit={this.handleFormSubmit.bind(this)}
                            handleCheckbox={this.handleCheckbox.bind(this)}
                            />

          </form>
        </div>
        <ChurchHomeFooter />
      </div>
    );
  }
}

const FormHeader = (props) => {
  return (
    <div className="formHeader">
      <h1>{props.selectedChurch}</h1>
    </div>
  );
}
