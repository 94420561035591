import React from 'react';

export class ChurchHomeFooter extends React.Component {

  render() {
    return (
      <div className="churchHomeFooter">
      </div>
    );
  }
}
