import React from "react";
import { SearchForm } from '../formsearch';

import {Input} from '../input-types/Input.jsx';

export class ChurchSubmissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  formNavigation(event) {
    var formLocation = event.target.dataset.location;
    var nextLocation = Number(formLocation) + 1;
    var prevLocation = Number(formLocation) - 1;
    const allSections = document.getElementById('formBody').childNodes;

    var i;
    var sections = [];
    for (i=0; i < allSections.length; i++) {
      sections.push(i);
    }

    var nextLocationIndex = sections.indexOf(nextLocation);
    var prevLocationIndex = sections.indexOf(prevLocation);

    if (event.target.dataset.direction === 'next') {
      allSections[sections[nextLocationIndex]].classList.remove('hideSection');
      sections.splice(nextLocationIndex, 1);

      for (i=0; i < sections.length; i++) {
        allSections[sections[i]].classList.add('hideSection');
      }
    } else {
      allSections[sections[prevLocationIndex]].classList.remove('hideSection');
      sections.splice(prevLocationIndex, 1);

      for (i=0; i < sections.length; i++) {
        allSections[sections[i]].classList.add('hideSection');
      }
    }

  }

  render() {
    return (
      <div id="formBody">
        <ChurchBasics {...this.props} formNavigation={this.formNavigation.bind(this)}/>
        <ChurchOnline {...this.props} formNavigation={this.formNavigation.bind(this)}/>
        <ChurchAddress {...this.props} formNavigation={this.formNavigation.bind(this)}/>
        <ChurchData {...this.props} formNavigation={this.formNavigation.bind(this)}/>
        <ChurchDemographics {...this.props} formNavigation={this.formNavigation.bind(this)}/>
      </div>
    );
  }
}

class ChurchDemographics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      churchSizeOptions: [
        'Under 150 members/participants in worship',
        'Between 150-300 members/participants in worship',
        'Over 300 members/participants in worship'
      ],
      ethnicityOptions: [
        'Prefer not to say',
        'African-American',
        'East Asian',
        'Hispanic/Latinx',
        'Korean',
        'Multiracial/mixed',
        'South Asian',
        'White',
      ],
      languageOptions: [
        'Prefer not to say',
        'American Sign Language',
        'Bilingual',
        'Chinese',
        'English',
        'Korean',
        'Spanish',
      ],
    }
  }

  render() {
    return (
      <div className="hideSection">
        <div className="formtext">
          <label>How many people actively participate at {this.props.selectedChurch}?</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox">
          {this.state.churchSizeOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline">
                <input
                  name='churchSize'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={1}
                  type="checkbox" />{option}
              </label>
            );
          })}
        </div>

        <div className="formtext">
          <label>What is the primary ethnicity of the congregation?</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox">
          {this.state.ethnicityOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline">
                <input
                  name='primaryEthnicity'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={1}
                  type="checkbox" />{option}
              </label>
            );
          })}
        </div>

        <div className="formtext">
          <label>What is the primary language spoken by the congregation?</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox">
          {this.state.languageOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline">
                <input
                  name='primaryLanguage'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={1}
                  type="checkbox" />{option}
              </label>
            );
          })}
        </div>
        <span onClick={this.props.formNavigation} data-location={4} data-direction={'back'} className='formNavigationBack'>Back</span>
        <span data-location={4} data-direction={'next'} className='formNavigationNext' onClick={this.props.handleFormSubmit}>Submit</span>
      </div>
    );
  }
}

class ChurchData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      denominationOptions: [
        'Select a denomination',
        'Southern Baptist',
        'American Baptist',
        'Free-will Baptist',
        'Federated Baptist',
        'Baptist (other)',
        'Lutheran Church - Missouri Synod',
        'Evangelical Lutheran Church in America',
        'United Methodist Church',
        'Free Methodist',
        'Southern Methodist',
        'American Methodist Episcopal Church',
        'Episcopal Church',
        'Anglican (other)',
        'Roman Catholic',
        'Old Catholic',
        'Church of Christ',
        'Bible Church',
        'United Church of Christ',
        'Presbyterian Church in America',
        'Presbyterian Church (U.S.A.)',
        'Evangelical Covenantal Order of Presbyterians',
        'Orthodox Presbyterian Church',
        'Evangelical Covenant Church',
        'Church of God',
        'Assemblies of God',
        'Pentecostal (other)',
        'Unitarian Universalist',
        'Non-denominational',
        'Inter-denominational',
        'Ecumenical',
        'Quaker',
        'Not Listed (ChurchHome will reach out)',
      ],
      employeeCountOptions: [
        'Under 10', 'Between 10-20', 'Over 20'
      ],
      cbsAreaLevelOptions: [
        'Rural','Suburban','Urban'
      ],
    }
  }

  // <select onChange={this.props.onChange} value={this.props.userEntry.churchDenomination} name={'churchDenomination'}>
  //   {this.state.denominationOptions.map(option => {
  //     return (
  //       <option value={option} key={option}>{option}</option>
  //     );
  //   })}
  // </select>

  render() {
    return (
      <div className="hideSection">
        <div className="formtext">
          <label className="form-label formtext">What denomination or organization is {this.props.churchName} affiliated with?</label>
          <p className="helpText"></p>
        </div>
        <SearchForm placeholder="Search for a denomination..." apiURL={`https://api.churchhome.co/v1/denominations/{items}`} primaryKey={'denominationName'} displayKey={'denominationName'} renderedResult={'simple'}/>

        <div className="formtext">
          <label className="form-label formtext">In what type of area is this church located?</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox twoCol">
          {this.state.cbsAreaLevelOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline checkboxRegular">
                <input
                  name='cbsAreaLevel'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={1}
                  type="checkbox"/>{option}
              </label>
            );
          })}
        </div>

        <div className="formtext">
          <label className="form-label formtext">How many employees work at this church?</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox twoCol">
          {this.state.employeeCountOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline checkboxRegular">
                <input
                  name='employeeCount'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={1}
                  type="checkbox"/>{option}
              </label>
            );
          })}
        </div>

        <Input  inputtype={'text'}
                title= {'Name of head/lead pastor'}
                name= {'leadPastorName'}
                className="leadPastor"
                value={this.props.userEntry.leadPastorName}
                placeholder={'Alex Apple'}
                onChange={this.props.onChange}
                />

        <span onClick={this.props.formNavigation} data-location={3} data-direction={'back'} className='formNavigationBack'>Back</span>
        <span onClick={this.props.formNavigation} data-location={3} data-direction={'next'} className='formNavigationNext'>Next</span>
      </div>
    );
  }
}

class ChurchAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="hideSection">
        <Input  inputtype={'text'}
                title= {'Street Address'}
                name= {'churchAddressStreet'}
                value={this.props.userEntry.churchAddressStreet}
                placeholder={'1 Church St.'}
                onChange={this.props.onChange}
                />
        <Input  inputtype={'text'}
                title= {'City'}
                name= {'churchAddressCity'}
                value={this.props.userEntry.churchAddressCity}
                placeholder={'Providence'}
                onChange={this.props.onChange}
                />

        <Input  inputtype={'text'}
                title= {'State'}
                name= {'churchAddressState'}
                value={this.props.userEntry.churchAddressState}
                placeholder={'RI'}
                onChange={this.props.onChange}
                />

        <Input  inputtype={'text'}
                title= {'ZIP'}
                name= {'churchAddressZIP'}
                value={this.props.userEntry.churchAddressZIP}
                placeholder={'00316'}
                onChange={this.props.onChange}
                />

        <span onClick={this.props.formNavigation} data-location={2} data-direction={'back'} className='formNavigationBack'>Back</span>
        <span onClick={this.props.formNavigation} data-location={2} data-direction={'next'} className='formNavigationNext'>Next</span>
      </div>
    );
  }
}

class ChurchOnline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="hideSection">
        <Input  inputtype={'text'}
                title= {'Website URL'}
                name= {'websiteURL'}
                value={this.props.userEntry.websiteURL}
                placeholder={'fcp.org'}
                onChange={this.props.onChange}
                />

        <Input  inputtype={'text'}
                title= {'Facebook URL (put N/A if not applicable)'}
                name= {'facebookURL'}
                value={this.props.userEntry.facebookURL}
                placeholder={'facebook.com/firstchurch'}
                onChange={this.props.onChange}
                />

        <Input  inputtype={'text'}
                title= {'Instagram Handle (put N/A if not applicable)'}
                name= {'instagramURL'}
                value={this.props.userEntry.instagramURL}
                placeholder={'firstchurch'}
                onChange={this.props.onChange}
                />

        <span onClick={this.props.formNavigation} data-location={1} data-direction={'back'} className='formNavigationBack'>Back</span>
        <span onClick={this.props.formNavigation} data-location={1} data-direction={'next'} className='formNavigationNext'>Next</span>
      </div>
    );
  }
}

class ChurchBasics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleOptions: [
        'Lead/head pastor',
        'Associate or assistant pastor',
        'Campus-specific pastor',
        'Other pastoral role',
      ],
    }
  }

  render() {
    return (
      <div className="">
      <Input  inputtype={'text'}
              title={'Church Name'}
              name={'churchName'}
              value={this.props.userEntry.churchName}
              placeholder={'First Church of Providence'}
              onChange={this.props.onChange}
              />

      <Input  inputtype={'text'}
              title= {'Contact Phone'}
              name= {'phoneNumber'}
              value={this.props.userEntry.phoneNumber}
              placeholder={'1112223333'}
              onChange={this.props.onChange}
              />

      <Input  inputtype={'text'}
              title={'Contact Email'}
              name={'emailAddress'}
              value={this.props.userEntry.emailAddress}
              placeholder={'contact@fcp.org'}
              onChange={this.props.onChange}
              />

        <span onClick={this.props.formNavigation} data-location={0} data-direction={'next'} className='formNavigationNext' id="back-button">Next</span>
      </div>
    );
  }
}
