import React from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

export class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      data: [],
      searchString:[],
      loading: true,
    };
  }

  validationHandler(e, primary, secondary) {
    this.setState({
      primaryReturnValue: primary,
      secondaryReturnValue: secondary
    });
  }

  handleInputChange = (event) => {
      this.setState({
        query: event.target.value
      },() => { this.filterArray() })
  }

  getData = () => {
    fetch(`${this.props.apiURL}`, {
      method: "GET",
      headers: {
        'Origin': 'churchhome.co',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': 'XyAg3Kp1Fc7buMV8jRMkA8K7fqYdkXfI3fDY28IA',
      }
    })
    .then(response => response.json())
    .then(responseData => {
        this.setState({
            data: responseData,
            searchString: responseData
        });
    }).then(responseData => {
      this.setState({
        loading: false
      })
    })
  }

  filterArray = () => {
    let searchString = this.state.query;
    let displayKey = this.props.displayKey;
    searchString = searchString.charAt(0).toUpperCase() + searchString.substring(1);
    let responseData = this.state.data.filter(entry => {
      let returnedResult = entry[displayKey].startsWith(searchString);
      return returnedResult;
    });

    if (searchString.length > 0) {
      this.setState({
        responseData: responseData,
        loading: false
      })
    }
  }

  componentWillMount() {
    this.getData();
  }

  renderSelectedChoice = (event) => {
    this.setState({
      selected: event.target.innerHTML
    });
  }

  removeSelectedChoice = (event) => {
    this.setState({
      selected: undefined,
      searchString: ''
    })
  }

  render() {

    if (this.state.responseData !== undefined) {
      // const mapKey = result[this.props.displayKey];
      // const displayKey = result[this.props.displayKey];
      //
      // const primaryReturnValue = result[this.props.primaryKey];
      // const secondaryReturnValue = result[this.props.displayKey];
      const returnURLPrefix = this.props.returnURLPrefix;

      if (this.state.selected !== undefined) {
        return (
          <div className="searchSelectedInput">
            <span id="selectedSearchOption">{this.state.selected}</span>
            <span onClick={this.removeSelectedChoice.bind(this)} id="remove">&#10005;</span>
          </div>
        );
      }

      if (this.props.renderedResult === 'linked') {
        return (
          <div className="validationForm">
            <input type="text" id="filter" placeholder={this.props.placeholder}  onChange={this.handleInputChange.bind(this)}/>
            {this.state.responseData.map((result) => <Link to={{
              pathname: `${returnURLPrefix}/${result[this.props.displayKey]}`,
              vars: {
                primaryReturnValue: result[this.props.primaryKey],
                secondaryReturnValue: result[this.props.displayKey],
              }
            }}><span className="searchResult" key={result[this.props.displayKey]}>{result[this.props.displayKey]}</span></Link>)}
          </div>
        );
      }

      if (this.props.renderedResult === 'simple') {
        return (
          <div className="validationForm">
            <input type="text" id="filter" placeholder={this.props.placeholder}  onChange={this.handleInputChange.bind(this)}/>
            {this.state.responseData.map((result) => <span className="searchResult" onClick={this.renderSelectedChoice.bind(this)} key={result[this.props.displayKey]}>{result[this.props.displayKey]}</span>)}
          </div>
        );
      }
    }

    return (
      <div className="validationForm">
          <input type="text" id="filter" placeholder={this.props.placeholder}  onChange={this.handleInputChange.bind(this)}/>
      </div>
    );
  }
}
