import React from "react";
import "../styles/ch-basic.scss";
import "../styles/forms.scss";
import {ChurchHomeFooter} from './footer';

import {PastorSubmissions} from './form/PastorSubmissions';

// Form ideas from https://github.com/blizzerand/react-forms
export class PastorSubmissionsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formLocation: [
        {pastorBasicsComplete: false},
      ],
      chars_left: 200,

      peopleDescriptors: [],
      pastorDemographics: [],

      ministryPrograms: [],
      childcareBackground: false,
      childcareCertified: false,

      serviceSchedule: [],
      sermonDescriptor: [],
      churchDescriptor: [],
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  notifyOfSubmission() {

    const userInput = this.state;
    const userData = (
      `
      <head>
        <link href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway&display=swap" rel="stylesheet">
      </head>
      <div style="background:-color: white;">
        <div style="
        background-color: #42A8DB;
        color: white;
        font-family: 'Raleway';
        width: 90%;
        padding: 7rem 5% 2rem;
        text-align: center;
        ">
        <h1 style="margin: 6px 0; font-size: 2.5rem;">ChurchHome</h1>
        <p style="
        margin: 0;
        ">Find your flock.</p>
      </div>

      <div style="
      font-family: 'Open Sans';
      width: 90%;
      padding: 5%;
      ">
      <h2 style="margin: 6px 0;">From the pastor of ${this.props.location.vars.secondaryReturnValue}</h2>
      <p style="color: #888; margin: 0;">Response submitted by <strong>${userInput.pastorResponseName}</strong> (${userInput.pastorResponseRole})</p>

      <div style="display: inline-block; width: 40%; min-width: 400px; margin: 4rem 0 2rem;">
        <img src="http://churchhome.co/assets/Clergy.png" style="width: 100px; height: auto;"/>
        <h3>Clergy demographics:</h3>
        <p>${userInput.pastorDemographics}</p>
        <h3>Sermon descriptors:</h3>
        <p>${userInput.sermonDescriptor}</p>
        <h3>Why is this church unique?</h3>
        <p>${userInput.pastorUnique}</p>
        <h3>Marriage means:</h3>
        <p>${userInput.pastorMarriage}</p>
        <h3>The Bible is:</h3>
        <p>${userInput.pastorBible}</p>
      </div>

      <div style="display: inline-block; width: 40%; min-width: 400px; margin: 4rem 0 2rem;">
        <img src="http://churchhome.co/assets/Church.png" style="width: 100px; height: auto;" />
        <h3>Service info:</h3>
        <p>${userInput.serviceSchedule}</p>
        <h3>Special programs:</h3>
        <p>${userInput.ministryPrograms}</p>
        <h3>Church brand:</h3>
        <p>${userInput.churchDescriptor}</p>
        <h3>Congregation descriptors:</h3>
        <p>${userInput.peopleDescriptors}</p>
        <h3>If I walk in alone, what should I expect?</h3>
        <p>${userInput.pastorWalkIn}</p>
      </div>

      <div style="display: inline-block; width: 40%; min-width: 400px;">
        <img src="http://churchhome.co/assets/Childcare.png" style="width: 100px; height: 100px;"/>
        <h3>What kind of childcare is offered?</h3>
        <p>${userInput.childCare}</p>
        <h3>Do childcare workers complete background checks & screenings?</h3>
        <p>${userInput.childcareBackground}</p>
        <h3>Is this church's childcare is certified?</h3>
        <p>${userInput.childcareCertified}</p>
      </div>

      <div>
        <a style="
        background-color: #42A8DB;
        color: white;
        display: block;
        margin: 4rem auto;
        width: 30%;
        padding: 0.75rem;
        text-align: center;
        border-radius: 1.5rem;
        text-decoration: none;
        " href="http://churchhome.co/approve/${this.props.church_id}">Approve</a>
      </div>
      </div>
      </div>
      `
    );

    let emailNotification = {
      "toEmails": ["michael@churchhome.co", "nikki@churchhome.co"],
      "subject": "New pastoral response",
      "message": userData
    }

    fetch('https://api.churchhome.co/v1/congregations/notify', {
      method: "POST",
      headers: {
        'Origin': 'churchhome.co',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': 'XyAg3Kp1Fc7buMV8jRMkA8K7fqYdkXfI3fDY28IA',
      },
      body: JSON.stringify(emailNotification),
    }).then(response => {
      console.log("Successfully saved " + userData);
    })
  }

  writeToDatabase() {
    const userInput = this.state;
    let dataToWrite = {
      "church_id": this.props.location.vars.primaryReturnValue,
      "approved": false,

      "pastorResponseName": userInput.pastorResponseName,
      "pastorResponseRole": userInput.pastorResponseRole,
      "pastorUnique": userInput.pastorUnique,

      "peopleDescriptors": userInput.peopleDescriptors,
      "pastorDemographics": userInput.pastorDemographics,

      "ministryPrograms": userInput.ministryPrograms,
      "childCare": userInput.childCare,
      "childcareBackground": userInput.childcareBackground,
      "childcareCertified": userInput.childcareCertified,

      "serviceSchedule": userInput.serviceSchedule,
      "pastorWalkIn": userInput.pastorWalkIn,
      "sermonDescriptor": userInput.sermonDescriptor,
      "churchDescriptor": userInput.churchDescriptor,

      "pastorMarriage": userInput.pastorMarriage,
      "pastorBible": userInput.pastorBible,
    }

    console.log("data to write = " + dataToWrite.church_id + ', and ' + dataToWrite.pastorResponseName);

    fetch('https://api.churchhome.co/v1/pastors/submit', {
      method: "POST",
      headers: {
        'Origin': 'churchhome.co',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': 'XyAg3Kp1Fc7buMV8jRMkA8K7fqYdkXfI3fDY28IA',
      },
      body: JSON.stringify(dataToWrite),
    }).then(response => {
      if (response.ok) {
        return response;
      } else {
        throw new Error('Something went wrong. The server responded with something other than 200.');
      }
    }).then(response => {
      console.log("Successfully saved.");

      if (response.ok) {
        var formBody = document.getElementById("formBody");
        formBody.parentNode.removeChild(formBody);
        var formContainer = document.getElementById("formContainer");
        console.log(formContainer);
        var confirmationMessage = "<h1>Thanks!</h1><p>We're excited to help others discover what is so special at your community.</p><a href='/'><button class='returnButton'>Return to home</button></a>"
        console.log(confirmationMessage);
        var confirmationElement = document.createElement('div');
        confirmationElement.classList.add('confirmationMessage');
        confirmationElement.innerHTML = confirmationMessage;
        formContainer.appendChild(confirmationElement);
      }
    }).catch(error => {
      console.log('error: ' + error);

      var formBody = document.getElementById("formBody");
      formBody.parentNode.removeChild(formBody);
      var formContainer = document.getElementById("formContainer");
      console.log(formContainer);

      var errorMessage = `<h1>Oops!</h1><p>Something didn't quite work.</p><a href='mailto:michael@churchhome.co?subject=Error%20Message&body=I%20received%20an%20error%20with%20the%20message:%20"${error}"".'><button class='returnButton'>Let us know</button></a>`
      console.log(errorMessage);
      var errorElement = document.createElement('div');
      errorElement.classList.add('confirmationMessage');
      errorElement.innerHTML = errorMessage;
      formContainer.appendChild(errorElement);
    })
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.notifyOfSubmission();
    this.writeToDatabase();
  }

  handleChange(event, checkboxId) {
    const target = event.target;
    var value = event.target.value;

    const inputName = target.name;
    this.setState({
      [inputName]: value
    });

    if (event.target.name === 'pastorDemographics') {
      this.setState({ pastorDemographics: [...this.state.pastorDemographics, event.target.value] })
    }

    if (event.target.name === 'churchDescriptors') {
      this.setState({ churchDescriptors: [...this.state.churchDescriptors, event.target.value] })
    }

    if (event.target.name === 'ministryPrograms') {
      this.setState({ ministryPrograms: [...this.state.ministryPrograms, event.target.value] })
    }

    if (event.target.name === 'sermonDescriptors') {
      this.setState({ sermonDescriptors: [...this.state.sermonDescriptors, event.target.value] })
    }

    if (event.target.name === 'peopleDescriptors') {
      this.setState({ peopleDescriptors: [...this.state.peopleDescriptors, event.target.value] })
    }

    if (event.target.name === 'serviceSchedule') {
      this.setState({ serviceSchedule: [...this.state.serviceSchedule, event.target.value] })
    }

    if ((event.target.name === 'pastorResponseName') && (event.target.value !== '')) {
      document.querySelector("section#thisSection").classList.add("pastorNameComplete");
    }
  }

  handleCheckbox(event) {
    const target = event.target;
    var value = event.target.value;
    const inputName = target.name;

    this.setState({
      [inputName]: value
    });

    if (event.target.name === 'peopleDescriptors') {
      this.setState({ peopleDescriptors: [...this.state.peopleDescriptors, event.target.value] })
    }

    if (event.target.name === 'sermonDescriptor') {
      this.setState({ sermonDescriptor: [...this.state.sermonDescriptor, event.target.value] })
    }

    if (event.target.name === 'churchDescriptor') {
      this.setState({ churchDescriptor: [...this.state.churchDescriptor, event.target.value] })
    }

    // Set maximum check limit
    var limit = event.target.dataset.limit;
    limit = Number(limit);

    // Create state to count checked boxes
    var checkCountKey = (inputName + 'Check');
    if (this.state[checkCountKey] === undefined) {
      var checkCount = 0;
      this.setState({
        [checkCountKey]: checkCount
      })
    } else {
      checkCount = this.state[checkCountKey];
    }

    var checkedClass = 'checked-' + inputName;
    var disabledClass = 'disabled-' + inputName;
    var hiddenClass = 'hideInput-' + inputName;

    // If the event was triggered by already checked input, uncheck it
    var eventWasChecked = event.target.classList.contains(checkedClass);
    if (eventWasChecked === true) {
      event.target.classList.add(disabledClass);
      event.target.classList.remove(checkedClass);

      // Remove a corresponding check in the count
      checkCount = checkCount - 1;
      this.setState({
        [checkCountKey]: checkCount
      })

      if (event.target.name === 'peopleDescriptors') {
        var peopleArray = [...this.state.peopleDescriptors]; // make a separate copy of the array
        var index = peopleArray.indexOf(event.target.value)
        if (index !== -1) {
          peopleArray.splice(index, 1);
          this.setState({peopleDescriptors: peopleArray});
        }
      }

      if (event.target.name === 'sermonDescriptor') {
        var sermonArray = [...this.state.sermonDescriptor]; // make a separate copy of the array
        index = sermonArray.indexOf(event.target.value)
        if (index !== -1) {
          sermonArray.splice(index, 1);
          this.setState({sermonDescriptor: sermonArray});
        }
      }

      if (event.target.name === 'churchDescriptor') {
        var churchArray = [...this.state.churchDescriptor]; // make a separate copy of the array
        index = churchArray.indexOf(event.target.value)
        if (index !== -1) {
          churchArray.splice(index, 1);
          this.setState({churchDescriptor: churchArray});
        }
      }

    } else {
      // Otherwise, increase checkcount on each event
      checkCount = checkCount + 1;
      this.setState({
        [checkCountKey]: checkCount
      })
    }

    // Get all checkboxes with the same input name
    var allRelatedBoxes = document.getElementsByName(inputName);

    // Loop to find checked boxes and update classes in DOM
    var i;
    for (i=0; i < allRelatedBoxes.length; i++) {
      if (allRelatedBoxes[i].checked === true) {
        allRelatedBoxes[i].classList.add(checkedClass);
        allRelatedBoxes[i].classList.remove(disabledClass);
      } else {
        allRelatedBoxes[i].classList.add(disabledClass);
        allRelatedBoxes[i].classList.remove(checkedClass);
      }
    }

    // If limit is reached, hide the unchecked boxes
    if (checkCount === limit) {
      var disabledBoxes = document.getElementsByClassName(disabledClass);
      for (i=0; i < disabledBoxes.length; i++) {
        disabledBoxes[i].parentNode.classList.add(hiddenClass);
      }
      document.querySelector("section#thisSection").classList.add("allChecksComplete");
    } else if (checkCount !== limit){
      disabledBoxes = document.getElementsByClassName(disabledClass);
      for (i=0; i < disabledBoxes.length; i++) {
        disabledBoxes[i].parentNode.classList.remove(hiddenClass);
      }
      document.querySelector("section#thisSection").classList.remove("allChecksComplete");
    }
  }

  handleTextArea(event) {
    const target = event.target;
    const inputName = target.name;
    this.setState({
      [inputName]: event.target.value
    });

    if (event.target.value !== '') {
      document.querySelector("section#thisSection").classList.add("allTextAreasComplete");
    } else {
      document.querySelector("section#thisSection").classList.remove("allTextAreasComplete");
    }

    const charCount = event.target.value.length;
    const charLeft = 200 - charCount;
    this.setState({
      chars_left: charLeft
    });

    if (charLeft < 40) {
      document.getElementById('textResponseArea').classList.add('limit');
    } else {
      document.getElementById('textResponseArea').classList.remove('limit');
    }
  }

  componentDidMount() {
    console.log('this.state.church_id = ' + this.props.location.vars.primaryReturnValue);
  }

  render() {

    // if (this.state.pastorResponseName === '') {
    //   document.querySelector("section#thisSection").classList.remove("pastorNameComplete");
    // }

    if (this.state.formLocation[0].pastorBasicsComplete === false ) {
      return (
        <div className="formBody">
          <FormHeader selectedChurch={this.props.location.vars.secondaryReturnValue} />
          <div id="formContainer">
            <form className="container-fluid" onSubmit={this.handleFormSubmit}>

              <PastorSubmissions onChange={this.handleChange.bind(this)}
                              onTextArea={this.handleTextArea.bind(this)}
                              userEntry={this.state}
                              handleFormSubmit={this.handleFormSubmit.bind(this)}
                              handleCheckbox={this.handleCheckbox.bind(this)}
                              selectedChurch={this.props.location.vars.secondaryReturnValue}
                              completion={this.state.completion}
                              />
            </form>
          </div>
          <ChurchHomeFooter />
        </div>
      );
    }
  }
}

const FormHeader = (props) => {
  return (
    <div className="formHeader">
      <h1>{props.selectedChurch}</h1>
    </div>
  );
}
