import React from "react";
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";

import "../styles/ch-basic.scss";
import "../styles/forms.scss";

import {SearchForm} from './formsearch';

export class SubmissionLanding extends React.Component {  
  render() {
    return (
      <React.Fragment>
        <Link to="/" className="close-btn"><span id="back">&#10005;</span></Link>
        <div className="searchForm submissionLanding">

          <SearchForm placeholder="Find my congregation..." apiURL={`https://api.churchhome.co/v1/churches/{items}`} primaryKey={'church_id'} displayKey={'churchName'} renderedResult={'linked'} returnURLPrefix={this.props.location.pathname}/>
          <div className="submit-new-container">
            <p>Can't find your church?</p>
            <Link to="/submit/churches/"><button>Submit new church</button></Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
