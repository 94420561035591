import React from "react";
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import {ChurchHomeFooter} from "./components/footer";

import {ChurchSubmissionsContainer} from "./components/ChurchSubmissionsContainer";
import {SubmissionLanding} from "./components/SubmissionLanding";
import {ApproveSubmission} from "./components/approve";
import {PastorSubmissionsContainer} from "./components/PastorSubmissionsContainer";
import {MemberSubmissionsContainer} from "./components/MemberSubmissionsContainer";

import "./styles/ch-basic.scss";
import "./styles/landing.scss";

function App() {
  return (
    <Router>
      <div>
        <Route exact path="/" component={Landing} />
        <Route exact path="/submit/churches/" component={ChurchSubmissionsContainer} />
        <Route exact path="/submit/pastors/:churchName" component={PastorSubmissionsContainer} />
        <Route exact path="/submit/pastors/" component={SubmissionLanding} />
        <Route exact path="/submit/congregants/:churchName" component={MemberSubmissionsContainer} />
        <Route exact path="/submit/congregants/" component={SubmissionLanding} />
        <Route path="/approve/:church_id" component={ApproveSubmission} />
      </div>
    </Router>
  );
}

export class Header extends React.Component {
  render() {
    return (
      <nav>
        <ul>
          <li><NavLink exact={true} to="/">Home</NavLink></li>
          <li><NavLink exact={true} to="/submit/pastors">Pastors</NavLink></li>
        </ul>
      </nav>
    );
  }
}

class Landing extends React.Component {

  render() {
    return (
      <div>
        <Header />
        <div className="landingHeader">
          <h1 className="title">ChurchHome</h1>
          <h2>Find your flock.</h2>
        </div>

        <div className="landingBlock regularMargin floatLeft">
          <h3>Why should anyone go to church?</h3>
          <p>
            At ChurchHome, we believe that all people are made in the image of God and are loved by God. We also believe that many churches across the country are doing oustanding work in both showing and teaching people about this love.
          </p>
          <p>
            From hosting Al Anon meetings to providing premarital counseling, pastors and priests are supporting people in the highs and lows of everyday life. Church is, at best, a community of people learning and growing in God’s love together, multiplying one another’s joys and dividing their sorrows.
          </p>
        </div>

        <div className="landingBlock regularMargin floatRight">
          <h3>Who should take this quiz?</h3>
          <p>
            Broadly speaking, we believe that every church-seeking or church-curious person can benefit from ChurchHome. Although our goal is, in fact, for you to become rooted in and thrive through a church community, our vision is broader than that: even if you don’t immediately check out the church that we recommend, we believe that you’ll be better equipped by simply knowing that you’ll be welcomed and accepted in the place.
          </p>
          <p>
            On a granular level, ChurchHome particularly caters to those who recently moved to a new area, weekend travelers, people seeking grief counseling or another type of pastoral support, and those who are just curious about what else is out there.
          </p>
        </div>

        <div className="submitPrompt">
          <h1>Submit now</h1>
          <h2>Add info online for the Princeton beta</h2>
          <Link to="/submit/pastors">
            <button className="inviteButton">Submit as a pastor</button>
          </Link>
        </div>

        <ChurchHomeFooter />
      </div>
    );
  }
}

// <div className="whiteBackground selectFormMain regularMargin">
// <Link to="/submit/churches">
//   <div className="selectionBox">
//     <img src="./assets/churchsteeple.jpg" alt="a church steeple against a blue sky"/>
//     <h2>For churches</h2>
//     <div className="hiddenBox">
//       <h3>Submit a church &#10230;</h3>
//     </div>
//   </div>
// </Link>
// <Link to="/submit/pastors">
//   <div className="selectionBox">
//     <img src="./assets/readingbible.jpg" alt="pastor studies Bible"/>
//     <h2>For pastors</h2>
//     <div className="hiddenBox">
//       <h3>Share your passion &#10230;</h3>
//     </div>
//   </div>
// </Link>
// <Link to="/submit/congregants">
//   <div className="selectionBox">
//     <img src="./assets/laughingpeople.jpg" alt="two people laugh together"/>
//     <h2>For congregants</h2>
//     <div className="hiddenBox">
//       <h3>Share your experience &#10230;</h3>
//     </div>
//   </div>
// </Link>
// </div>


export default App;
