import React from 'react';

export const Input = (props) => {
	//console.log(props.value);
	return (
  <div className="form-group">
    <input
      className="form-control"
      id={props.name}
      name={props.name}
      type={props.inputtype}
      value={props.value}
      onChange={props.handleChange}
      placeholder={props.placeholder}
      {...props} />
			<label htmlFor={props.name} className="form-label">{props.title}</label>
  </div>
)
}
