import React from 'react';

export class ApproveSubmission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const updateApproval = {
      "approved": "true"
    }
    console.log('path: ' + this.props.match.params.church_id);
    fetch(`https://api.churchhome.co/v1/pastors/approve/${this.props.match.params.church_id}`, {
      method: "PUT",
      headers: {
        'Origin': 'churchhome.co',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': 'XyAg3Kp1Fc7buMV8jRMkA8K7fqYdkXfI3fDY28IA',
      },
      body: JSON.stringify(updateApproval),
    })

    this.setState({
      loading: false
    });
  }

  render() {
    if (this.state.loading === false) {
      return (
        <div>
          <div className="landingHeader">
            <h1 className="title">Approved</h1>
            <h2>This church is becoming a better ChurchHome.</h2>
          </div>
          <div className="regularMargin">
            <a href="/"><button className="returnButton">Return to homepage</button></a>
          </div>
        </div>
      );
    }

    return (
      <div className="fullpageLoading">
        <span className="loadingIcon"></span>
      </div>
    );
  }
}
