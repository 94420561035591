import React from "react";

import {Input} from '../input-types/Input.jsx';

export class MemberSubmissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  formNavigation(event) {
    var formLocation = event.target.dataset.location;
    var nextLocation = Number(formLocation) + 1;
    var prevLocation = Number(formLocation) - 1;
    const allSections = document.getElementById('formBody').childNodes;

    var i;
    var sections = [];
    for (i=0; i < allSections.length; i++) {
      sections.push(i);
    }

    var nextLocationIndex = sections.indexOf(nextLocation);
    var prevLocationIndex = sections.indexOf(prevLocation);

    if (event.target.dataset.direction === 'next') {
      allSections[sections[nextLocationIndex]].classList.remove('hideSection');
      allSections[sections[nextLocationIndex]].setAttribute("id","thisSection");
      sections.splice(nextLocationIndex, 1);

      for (i=0; i < sections.length; i++) {
        allSections[sections[i]].classList.add('hideSection');
        allSections[sections[i]].setAttribute("id", "");
      }
    } else {
      allSections[sections[prevLocationIndex]].classList.remove('hideSection');
      allSections[sections[prevLocationIndex]].setAttribute("id", "thisSection");
      sections.splice(prevLocationIndex, 1);

      for (i=0; i < sections.length; i++) {
        allSections[sections[i]].classList.add('hideSection');
        allSections[sections[i]].setAttribute("id", "");
      }
    }
  }

  render() {
    return (
      <div id="formBody">
        <PastorBasics {...this.props} formNavigation={this.formNavigation.bind(this)}/>
        <PastorDemographics {...this.props} formNavigation={this.formNavigation.bind(this)}/>
        <PastorMinistries {...this.props} formNavigation={this.formNavigation.bind(this)}/>
        <PastorServices {...this.props} formNavigation={this.formNavigation.bind(this)}/>
        <PastorFaith {...this.props} formNavigation={this.formNavigation.bind(this)}/>
      </div>
    );
  }
}

class PastorFaith extends React.Component {
  constructor(props) {
    super(props);
    this.marriageCheckbox = React.createRef();
    this.state = {
      marriageOptions: [
        'Marriage is a holy covenant between a man and a woman as outlined in the Bible.',
        'I hold a traditional view of marriage, but believe that all people regardless of their view on marriage should be loved and welcomed in a church community.',
        'I believe marriage within the church should be defined as it is in the Bible but recognize non heteronormative marriages by the state.',
        'Marriage is a union between two people regardless of sexual orientation or gender identity.',
      ],
      bibleOptions: [
        'The Bible should be read and interpreted literally as it is God’s perfect Word.',
        'The Bible is God-breathed and without error.',
        'The Bible is authoritative and divinely inspired.',
        'The Bible encourages a number of different interpretations and must be read alongside other interpretations.',
      ],
    }
  }

  render() {
    return (
      <section className="hideSection">
        <div className="formtext">
          <label>For numerous congregations and denominations, marriage has been a topic of contention. What does marriage mean to this church community?</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox requiredField" >
          {this.state.marriageOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline">
                <input
                  name='pastorMarriage'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={1}
                  type="checkbox" />{option}
              </label>
            );
          })}
        </div>

        <div className="formtext">
          <label>Describe your approach to the Bible.</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox requiredField" >
          {this.state.bibleOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline">
                <input
                  name='pastorBible'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={1}
                  type="checkbox" />{option}
              </label>
            );
          })}
        </div>
        <span onClick={this.props.formNavigation} data-location={4} data-direction={'back'} className='formNavigationBack'>Back</span>
        <span data-location={4} data-direction={'next'} className='formNavigationNext' id="next-button" onClick={this.props.handleFormSubmit}>Submit</span>
      </section>
    );
  }
}

class PastorServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceOptions: [
        'Childcare during services',
        'Multiple Sunday service times',
        'Evening services',
        'Weekday services',
        'None of the above'
      ],
      churchDescriptors: [
        'Bible-centered teaching',
        'Charismatic gifts',
        'Discipleship',
        'Eco-Sustainability',
        'Gender/sexuality justice',
        'Healing Prayer',
        'LGBTQ+ activism',
        'Overseas mission work',
        'Patriotic values',
        'Pro-choice values',
        'Pro-life values',
        'Racial justice',
        'Radical hospitality',
        'Safe spaces for worship',
        'Spirit-filled worship',
        'Spreading the Gospel (evangelism)',
        'Traditional family values',
      ],
      sermonDescriptors: [
        'Biblical',
        'Concise',
        'Global',
        'Intellectual',
        'Lectionary-based',
        'Narrative',
        'Passionate',
        'Personal',
        'Practical',
        'Progressive',
        'Socially-Relevant',
        'Thorough',
        'Truth-telling',
        'Vulnerable',
      ],
    }
  }

  render() {
    return (
      <section className="hideSection">
        <div className="formtext">
          <label className="form-label formtext">Does this church regularly schedule any of the following:</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox twoCol requiredField" >
          {this.state.serviceOptions.map(serviceOption => {
            return (
              <label key={serviceOption} className="checkbox-inline">
                <input
                  name='serviceSchedule'
                  onChange={this.props.onChange}
                  value={serviceOption}
                  type="checkbox"/>{serviceOption}
              </label>
            );
          })}
        </div>

        <div className="formtext">
          <label className="form-label">Q: If I walk in alone, what should I expect?</label>
        </div>
        <textarea id="textResponseArea"
          className="form-control requiredField"
          name='pastorWalkIn'
          value={this.props.userEntry.pastorWalkIn}
          maxLength='200'
          placeholder='A fresh cup of coffee and...'
          onChange={this.props.onTextArea} />
        <p className="characterCount">{this.props.userEntry.chars_left}</p>

        <div className="formtext">
          <label className="form-label formtext">Pick 3 descriptors that you value in your own sermon/message.</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox twoCol" >
          {this.state.sermonDescriptors.map(option => {
            return (
              <label key={option} className="checkbox-inline checkboxRegular">
                <input
                  name='sermonDescriptor'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={3}
                  type="checkbox"/>{option}
              </label>
            );
          })}
        </div>

        <div className="formtext">
          <label className="form-label formtext">What 3 things would you like people to associate with {this.props.selectedChurch}?</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox twoCol requiredField" >
          {this.state.churchDescriptors.map(churchDescriptor => {
            return (
              <label key={churchDescriptor} className="checkbox-inline checkboxRegular">
                <input
                  name='churchDescriptor'
                  onChange={this.props.handleCheckbox}
                  value={churchDescriptor}
                  data-limit={3}
                  type="checkbox"/>{churchDescriptor}
              </label>
            );
          })}
        </div>

        <span onClick={this.props.formNavigation} data-location={3} data-direction={'back'} className='formNavigationBack'>Back</span>
        <span onClick={this.props.formNavigation} data-location={3} data-direction={'next'} className='formNavigationNext' id="next-button">Next</span>
      </section>
    );
  }
}

class PastorMinistries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ministryOptions: [
        'Grief support',
        'AA/Al Anon',
        'Narcotics Anonymous',
        'NAMI meetings',
        'Premarital counseling',
        'None of the above'
      ],
    }
  }

  render() {
    return (
      <section className="hideSection">
        <div className="formtext">
          <label className="form-label formtext">Does {this.props.selectedChurch} regularly provide any of the following programs:</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox twoCol requiredField" >
          {this.state.ministryOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline">
                <input
                  name='ministryPrograms'
                  onChange={this.props.onChange}
                  value={option}
                  type="checkbox"/>{option}
              </label>
            );
          })}
        </div>

        <div className="formtext">
          <label className="form-label">Q: What kind of childcare is offered?</label>
        </div>
        <textarea id="textResponseArea requiredField"
          className="form-control"
          name='childCare'
          value={this.props.userEntry.childCare}
          maxLength='200'
          placeholder='Our childcare is offered at our services in the...'
          onChange={this.props.onTextArea} />
        <p className="characterCount">{this.props.userEntry.chars_left}</p>

        <div className="checkbox">
          <label className="checkbox-inline checkboxRegular">
            <input
              name='childcareBackground'
              onChange={this.props.onChange}
              value={true}
              type="checkbox" />Childcare workers complete background checks & screening.
          </label>
          <label className="checkbox-inline checkboxRegular">
            <input
              name='childcareCertified'
              onChange={this.props.onChange}
              value={true}
              type="checkbox" />This church provides childcare that is certified through a national program like SafeSanctuaries that requires training and standardized policies.
          </label>
        </div>

        <span onClick={this.props.formNavigation} data-location={2} data-direction={'back'} className='formNavigationBack'>Back</span>
        <span onClick={this.props.formNavigation} data-location={2} data-direction={'next'} className='formNavigationNext' id="next-button">Next</span>
      </section>
    );
  }
}

class PastorDemographics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptorOptions: [
        'Traditional families',
        'Single parents',
        'College students',
        'Young adults (non-students)',
        'Single/unmarried',
        'Older adults',
        'Middle-age',
        'Working professionals',
      ],
      demographicOptions: [
        'African-American',
        'Chinese-American',
        'Female',
        'Hispanic/Latinx',
        'Indian-American',
        'Japanese-American',
        'Korean-American',
        'LGBTQ+',
        'Multiracial/mixed',
        'White'
      ]
    }
  }

  render() {
    return (
      <section className="hideSection">
        <div className="formtext">
          <label className="form-label formtext">Out of the following options, what are the three best or most visible descriptors for those that attend services?</label>
          <p className="helpText"></p>
        </div>
        <div className="checkbox twoCol requiredField" >
          {this.state.descriptorOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline checkboxRegular">
                <input
                  name='peopleDescriptors'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={3}
                  type="checkbox" />{option}
              </label>
            );
          })}
        </div>

        <div className="formtext">
          <label className="form-label">Do you or other clergy identify as any of the following?</label>
          <p className="helpText">Some may feel their beliefs affirm the call of certain people to ministry. Others may want to worship with a pastor who looks like them or know there are clergy who can support the challenges in their specific context.</p>
        </div>
        <div className="checkbox twoCol requiredField" >
          {this.state.demographicOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline checkboxRegular">
                <input
                  name='pastorDemographics'
                  onChange={this.props.onChange}
                  value={option}
                  type="checkbox" />{option}
              </label>
            );
          })}
        </div>

        <span onClick={this.props.formNavigation} data-location={1} data-direction={'back'} className='formNavigationBack'>Back</span>
        <span onClick={this.props.formNavigation} data-location={1} data-direction={'next'} className='formNavigationNext' id="next-button">Next</span>
      </section>
    );
  }
}

class PastorBasics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleOptions: [
        'Lead/head pastor',
        'Associate or assistant pastor',
        'Campus-specific pastor',
        'Other pastoral role',
      ],
    }
  }

  render() {
    return (
      <section className="" id="thisSection">
        <Input  inputtype={'text'}
                title='Your name'
                name='pastorResponseName'
                value={this.props.userEntry.pastorResponseName}
                placeholder={'Alex Apple'}
                onChange={this.props.onChange}
                />

        <div className="formtext">
          <label className="form-label">Your role at {this.props.selectedChurch}</label>
        </div>
        <div className="checkbox requiredField" >
          {this.state.roleOptions.map(option => {
            return (
              <label key={option} className="checkbox-inline">
                <input
                  name='pastorResponseRole'
                  onChange={this.props.handleCheckbox}
                  value={option}
                  data-limit={1}
                  type="checkbox" />{option}
              </label>
            );
          })}
        </div>

        <div className="formtext">
          <label className="form-label">Q: What makes {this.props.selectedChurch} unique?</label>
        </div>
        <textarea id="textResponseArea requiredField"
          className="form-control"
          name={'pastorUnique'}
          value={this.props.userEntry.pastorUnique}
          maxLength='200'
          placeholder='This church goes back to A.D. 33!'
          onChange={this.props.onTextArea} />
        <p className="characterCount">{this.props.userEntry.chars_left}</p>

        <span onClick={this.props.formNavigation} data-location={0} data-direction={'next'} className='formNavigationNext notAllowed' id="next-button">Next</span>
      </section>
    );
  }
}
